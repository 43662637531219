@mixin social-icons-brand-colours(
  $brand-colour,
  $background-colour,
  $icon-colour,
  $hover-icon-colour
) {
  @if $background-colour == "brand" {
    background-color: $brand-colour;

    &:hover {
      @include button-hover-state($brand-colour);
    }
  }

  @if $icon-colour == "brand" {
    &:before {
      color: $brand-colour;
    }
  }

  @if $hover-icon-colour == "brand" {
    &:hover:before {
      @if $icon-colour != "brand" {
        color: $brand-colour;
      } @else {
        color: darken($brand-colour, 10%);
      }
    }
  }
}
