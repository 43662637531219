// Set some pre-client vars here as this is before cascade of either client or global var defs
$white: #fff;
$body-colour: #292b2c;

// Detecting brightness
// See: http://www.davidhalford.com/thoughts/2013/auto-contrasting-text/

@function text-contrast($colour) {
  $colour-brightness: round(red($colour) * 299 + green($colour) * 587 + blue($colour) * 114 / 1000);
  $light-colour: round(red(#ffffff) * 299 + green(#ffffff) * 587 + blue(#ffffff) * 114 / 1000);

  // Brand primary override
  @if $colour == $brand-primary {
    @if variable-exists(brand-primary-text-contrast-override) {
      @if $brand-primary-text-contrast-override != null {
        @return $brand-primary-text-contrast-override;
      }
    }
  }

  // Brand secondary override
  @if $colour == $brand-secondary {
    @if variable-exists(brand-secondary-text-contrast-override) {
      @if $brand-secondary-text-contrast-override != null {
        @return $brand-secondary-text-contrast-override;
      }
    }
  }

  @if $colour == transparent {
    @return $body-colour;
  }

  @else {
    @if abs($colour-brightness) < $light-colour / 2 {
      @return $white;
    }
    @else {
      @return $body-colour;
    }
  }
}

// This function takes two colours and checks for the colour difference scale when the foreground text colour appears on a field of background colour
// The benchmark here is 500, as per the furthest right check on https://contrastchecker.com/

@function colour-contrast-two-colours($foreground, $background) {
  $r: (max(red($foreground), red($background))) - (min(red($foreground), red($background)));
  $g: (max(green($foreground), green($background))) - (min(green($foreground), green($background)));
  $b: (max(blue($foreground), blue($background))) - (min(blue($foreground), blue($background)));
  $sum-rgb: $r + $g + $b;

  @if $sum-rgb < 500 {
    @return false;
  } @else {
    @return true;
  }
}