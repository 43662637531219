body {
    overflow-x: hidden;
}

// Useful mixin for before and after dividers
@mixin beforeafter {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 102%;
    overflow: hidden;
}
  
// Add divider to carousel
body:not(.page-no-banner) .carouselSlides {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((175 / 1600) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "carousel-curve.svg");
        background-position: bottom;
    }
}

// Add divider to stats
.homefeaturecategory-homeboximpactstats {
    position: relative;
    padding-top: $spacer * 7;
    overflow: hidden;
    &:after {
        @include beforeafter;
        height: calc((175 / 1600) * 100vw);
        top: -1px;
        background-image: url($assets-path + "white-curve.svg");
        background-position: top;
        transform: scaleY(-1);
    }
    // &:before {
    //     @include beforeafter;
    //     height: calc((175 / 1600) * 100vw);
    //     bottom: -1px;
    //     background-image: url($assets-path + "white-curve.svg");
    //     background-position: bottom;
    //     transform: scale(1.4);
    // }
}

// Add divider to footer
.pageFooterWrapper {
    position: relative;
    margin-top: $spacer * 6;
    &:after {
        @include beforeafter;
        height: calc((175 / 1600) * 100vw);
        top: calc(((-175 / 1600) * 100vw) + 2px);
        background-image: url($assets-path + "footer-curve.svg");
        background-position: bottom;
        transform: scaleX(-1);
    }
}

.pageFooter {
    padding-top: 0;
}

// .homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
//     padding-left: 3rem;
// }
.homeFeedBox1 {
    padding-top: $spacer * 3;
}

@media (min-width: map-get($breakpoints, xl)) {

// .homeFeedBox1 {
//     padding-top: 0;
// }

// .homeFeedBox1 .feedList {
//     width: 100%;
//     max-width: 100%;
//     .feedItem {
//         margin: 0;
//     }
// }

// .homeFeedBox1 .feedsTitle {
//     position: absolute;
//     z-index: 1;
//     left: calc((100vw - 1230px) / 2);
//     color: white;
//     margin-top: $spacer * 2;
// }

// .homeFeedBox1 .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
//     left: calc((100vw - 1230px) / 2);
//     padding-bottom: $spacer * 3;
// }
}

.homeIntro strong {
    color: $brand-secondary;
}

.footerBox.footerBox4 {
    grid-column: 1 / span 16;
    background-color: white;
    margin: $spacer * 2 0 0 0;
    padding-bottom: $spacer * 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacer;
    img {
        height: auto;
        width: 150px;
        margin: $spacer;
    }
    @media (max-width: map-get($breakpoints, xl)) {
        flex-wrap: wrap;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        display: block;
        img {
            display: block;
            margin: $spacer auto;
        }
    }
}
// Remove bottom padding
.pageFooter {
    padding-bottom: 0;
}

footer.postFooterWrapper {
    margin-bottom: 4rem;
}

body:not(.pagesPostBody) #bodyForm {
    padding-bottom: $spacer * 4;
}

#bodyForm {
    padding-bottom: $spacer * 1;
}

.footerLinks li {
    font-weight: bold;
}

.copyright {
    font-size: 0.875em;
}

// @media (max-width: map-get($breakpoints, xl)) {
//     .homeFeedBox1 .feedsTitle {
//         left: $spacer;
//     }
//     .homeFeedBox1 .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
//         left: $spacer;
//     }
// }

.progress-meter__step--current:before {
    border: 2px solid $brand-secondary;
    background-image: url($assets-path + 'chevron-down.svg');
}

.progress-meter__step--current {
    color: $brand-secondary;
}

.menuAdminBasket:before {
    content: '\f07a';
    margin-right: 0;
}

a {
    outline-width: 0;
}

.footerBox3.Footertestimonial {
    h3 {
        font-size: 1.5em;
    }
    &:before {
        content: "\f10d";
        font-family: $icon-font-family;
        font-size: 3em;
        color: rgba(white, 0.2);
    }
    &:after {
        content: "\f10e";
        font-family: $icon-font-family;
        font-size: 3em;
        color: rgba(white, 0.2);
        display: block;
        text-align: right;
        margin-top: -1rem;
    }
}

@media (max-width: 1024px) {
    li.hasSubmenu > a:after {
        content: "\f067";
    }
    li.hasSubmenu > a.active:after {
        content: "\f068";
    }
}

@media (max-width: map-get($breakpoints, lg)) {
    .carouselSlideDetail {
        padding-bottom: 6rem;
    }
    .homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
        text-align: center;
    }
    body:not(.pagesPostBody) #bodyForm {
        padding-bottom: $spacer * 0;
    }
    #bodyForm {
        padding-bottom: $spacer * 0;
    }
}
@media (max-width: map-get($breakpoints, md)) {
    .carouselSlideDetail {
        padding-bottom: 4rem;
    }
}
@media (max-width: map-get($breakpoints, sm)) {
    .homeFeedBox1 .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) .feedTitle a {
        color: $brand-secondary !important;
        font-size: 1.25rem;
    }
    .homeFeedBox1 .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) > p {
        color: $grey-dark !important;
    }
}


// Wysiwyg client styles

blockquote.subsite-blue {
  background-color: $subsite-blue;
}

// Alternative blockquote
blockquote.alternative {
  position: relative; 
  padding: $spacer * 2.5;
  text-align: left;
  background-color: transparent;
  color: $body-colour;
  &:before, &:after {
      position: absolute;
      font-family: $icon-font-family;
      font-size: 2.25rem;
      color: $brand-secondary;
  }
  &:before {
      content: '\f10d';
      top: 0;
      left: 0;
  }
  &:after {
      content: '\f10e';
      bottom: 0;
      right: 0;
  }
  &.subsite-blue {
    &:before, &:after {
      color: $subsite-blue;
    }
  }
}

// Supporters box

p.textBox {
  border: 2px solid $brand-primary;
  padding: 30px;
  display: block;
  position: relative;
  margin: 40px 0 20px;
  /*&:before {
    content: 'supporters';
    position: absolute;
    background: $brand-primary;
    top: -20px;
    padding: 10px 20px;
    font-family: helvetica neue,Arial,sans-serif;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-weight: 700;
    font-size: .75em;
  }*/
  &.red {
    border-color: $brand-secondary;
    &:before {
      background: $brand-secondary;
    }
  }
  &.subsite-blue {
    border-color: $subsite-blue;
    &:before {
      background: $subsite-blue;
    }
  } 
}

// CTA button other colours 

a.cta-button {
  &.red {
    background: $brand-secondary;
    &:hover {
      background: darken($brand-secondary, 5%);
    }
  }
  &.subsite-blue {
    background: $subsite-blue;
    &:hover {
      background: darken($subsite-blue, 5%);
    }
  }
}

// Blockquote - Author
blockquote span.blockquote-author {
  font-size: $font-size-base !important;
  float: right;
  padding-right: $spacer;
}

.postContent {
    h1 { font-size: $page-title-font-size; }
    h2 { font-size: #{$font-size-h1 * 1.125}; }
    h3 { font-size: $font-size-h1; }
    h4 { font-size: $font-size-h2; }
    h5 { font-size: $font-size-h3; }
    h6 { font-size: $font-size-h4; }
}

[class*="image-link"] {
    border-radius: 5px;
}


// Event category - no sidebar
body.eventsPostBody.PostCategory_event-no-label {
    .shoppingList caption h2,
    .shoppingList .shopLabel.ticketLabel,
    .eventSecondStep .ticketDetails > legend {
        font-size: 0;
        opacity: 0;
        margin: 0;
    }
}

// Event category - no sidebar
body.eventsPostBody.PostCategory_event-no-sidebar .postAside.eventWidgetsWrapper {
    display: none;
}

// https://trello.com/c/cHjrmGCg/525-adviza-content-alignment-on-platform-terms-and-conditions
.terms-and-conditions-wrapper {
    text-align: left;
}

.homeFeedBox2 {
    padding-bottom: #{$spacer * 2.5};
}
.homeFeedTwitter {
    padding-top: #{$spacer * 2.5};
    background-color: $grey-lightest;
}


// Subsite homepage - Title
body.subsite.homepage .contentContainer > .headerText.headerTextSubsite,
body.subsite:not(.homepage) .headerText.headerTextSubsite { display: none; }

#menuAdmin li:not(.menuAdminBasket):not(.menuAdminLogin) {
    display: none;
}
#menuAdmin li.menuAdminLogin a {
    font-weight: bold;
}

body.subsite {
    // change first menu item to a home icon
    .menuMain .topLevel > li.level1:first-child > a {
        @media (min-width: $nav-breakpoint + 1px) {
            font-size: 0; // Hide the label
            padding-top: 0;
            padding-bottom: 0;
            height: 100%;
        }
        align-items: center;

        &:before {
        content: '\f015';
        font-family: $icon-font-family;
        display: block;
        font-size: $nav-top-level-first-item-home-icon-font-size;
        font-weight: $font-weight-normal;
            @media (max-width: $nav-breakpoint) {
                margin-right: 10px;
            }
        }

        &:after {
        display: none; // Hide any chevrons that might be there
        }
    }
}


body.userLoggedIn {
    .menuAdminLogin { display: none !important; }
    .menuAdminLogOut { display: block !important; }
    .menuAdminLogOut a { font-weight: bold; }
}

.mainLogo.logo-pride {
    background-image: url($assets-path + 'logo-pride.svg');
}

// Bucks subsite hide subsite elements added via js when post appears on main site and inherits main site styling instead of subsite
.bucksSubsiteLogos, .subsitePreFooter {
  display: none;
}