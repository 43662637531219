//
// Environment
//


//
// Config values
//

$platform-name: 'advizaconnexionsbuckinghamshir';


//
// Colours
//

$_grey: #E3E4E3;

$brand-primary: #141B4D;
$brand-secondary: #B51F2A;
$subsite-blue: #2160b1;

$donate-colour: $brand-secondary !default;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;


//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 980px;
$border-radius: 1.5px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates
$body-colour: $brand-primary;


//
// Transitions
//



//
// Logo
//

$logo-width: 232px;
$logo-height: 90px;

$logo: "logo-2024.png";

//
// Typography
//

$font-family-base: 'Mulish', sans-serif;

$font-weight-normal: 400;
$font-weight-bold: 800;
$font-size-base: 1.075rem;

$font-size-h1: 2em;
$font-size-h2: 1.75em;
$font-size-h3: 1.5em;
$font-size-h4: 1.25em;
$font-size-h5: 1em;
$font-size-h6: 1em;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content

$headings-colour: $brand-primary;

// Links
$link-colour: $brand-secondary;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-hover-style: lighten; // darken or lighten the button on hover
$btn-font-size: 1.05em;
// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific

// Footer specific social icons


//
// Share this page
//
$share-label-font-colour: $brand-primary;



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-heading-font-size: $font-size-h4;
$card-image-border-radius: 5px;
// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: .7 !default;


//
// Menu admin
//
$menu-admin-background-colour: $grey-lighter;
$menu-admin-absolute: false;
$menu-admin-logout-link-enabled: true;
//
// Header
//


// Header content
//$header-content-margin-top: $spacer * -0.25;
//$header-content-margin-bottom: $spacer * 1.25;
$header-content-padding-bottom: 0;

// Tagline

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//
$nav-background-colour: $_grey;
$nav-breakpoint: map-get($breakpoints, lg);


// Top level items
$nav-top-level-item-padding: 12.5px 20px;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: $brand-secondary;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-first-item-home-icon: false;


// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-hover-colour: $brand-secondary;

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: center; // left, center, right
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-mobile-submenu-background-colour: rgba(white, 0.3);


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-details-position-y: center; // top, center, bottom
$carousel-image-overlay: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.8) 100%); // Add a colour value or gradient here
$carousel-details-background-colour: transparent;
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-details-padding: 0;
$carousel-heading-font-size: $font-size-h1 * 1.25;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-button-background-colour: transparent;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: transparent;
$home-intro-margin-top: 0;
$home-intro-padding-y: $spacer * 2;
$home-intro-colour: $brand-primary;
$home-intro-font-size: $font-size-h1;


//
// Home features
//

$home-features-padding-top: $spacer * 2;
$home-features-padding-bottom: $spacer * 1;


//
// Impact stats
//
$impact-stats-padding-y: $spacer * 2;


// Heading
$impact-stats-heading-font-size: $font-size-h1;
$impact-stats-heading-text-align: left; // left, center, right
$impact-stats-heading-margin-bottom: $spacer * 1;
$impact-stats-heading-colour: rgba(white, 0.6);

// Individual stat

// Figure

// Summary


//
// Home feeds
//
$home-feeds-padding-top: $spacer * 0;


// Feeds title
$feeds-title-colour: $brand-primary;
$feeds-title-font-size: $font-size-h2;
// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed
$home-feed-twitter-enabled: true;
// $home-feed-twitter-card-gap-width: $card-gap-width !default;
$home-feed-twitter-card-border: 0.25px solid rgba($body-colour, 0.25);
$home-feed-twitter-card-border-radius: $card-image-border-radius;
// $home-feed-twitter-card-box-shadow: $card-box-shadow !default;
// $home-feed-twitter-card-text-align: $card-text-align !default;
// $home-feed-twitter-card-padding: $card-details-padding !default;
// $home-feed-twitter-card-background-colour: $card-details-background-colour !default;
// $home-feed-twitter-card-colour: text-contrast($home-feed-twitter-card-background-colour) !default;
// $home-feed-twitter-card-min-width: $card-min-width !default;



//
// Footer
//

$footer-background-colour: $brand-primary;
$footer-prefab: 4;
$footer-link-colour: rgba(white, 0.5);
$footer-link-hover-colour: rgba(white, 1);
$footer-link-decoration: none;
$footer-link-hover-decoration: none;

// Footer admin links

// Newsletter
$newsletter-heading-text-align: left; // left, center, right

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//
$page-title-font-size: $font-size-h1 * 1.25;
$page-title-margin-top: $spacer * 2;

//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//
$blockquote-max-width: $post-content-max-width;
$blockquote-background-colour: $brand-primary;
$blockquote-margin-y: $spacer * 2;
$blockquote-padding: $spacer * 2;
$blockquote-text-align: center;
$blockquote-font-size: 1.25em;
$blockquote-colour: text-contrast($blockquote-background-colour);


//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//


// Donation amounts


//
// Quick giving panel
//
$quick-giving-background-colour: $_grey;
$quick-giving-type: columns; // simple, columns or rows
// $quick-giving-background-colour: $context-container-background-colour !default;
// $quick-giving-background-gradient: none !default; // none or CSS gradient
// $quick-giving-padding: $context-container-padding !default;
// $quick-giving-border-radius: $context-container-border-radius !default;
// $quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75 !default;
// $quick-giving-donation-amount-padding: 0.5rem 1rem !default;
// $quick-giving-donation-amount-background-colour: $donate-colour !default;
// $quick-giving-donation-amount-figure-colour: text-contrast($quick-giving-donation-amount-background-colour) !default;
// $quick-giving-donation-amount-border-width: $btn-border-width !default;
// $quick-giving-donation-amount-border-colour: $donate-colour !default;
// $quick-giving-donation-amount-selected-background-colour: $white !default;
// $quick-giving-donation-amount-selected-figure-colour: text-contrast($quick-giving-donation-amount-selected-background-colour) !default;



//
// Home quick giving panel
//
$home-quick-giving-padding-y: $spacer * 3;
$home-quick-giving-heading-colour: $brand-primary;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: true;
$basket-link-only-on-shop-pages: true;

// Departments list

// Product-price

// Product post


// Subsite
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: true;
$subsite-hide-header-search: true;
$subsite-hide-main-cta: false;

// Subsite logo
$subsite-logo: "title";
$subsite-logo-width: auto;
$subsite-logo-height: auto;
$subsite-logo-margin: #{$spacer * 1.5} 0 0;

// // Subsite nav
// $subsite-nav-breakpoint: $nav-breakpoint !default;
// $subsite-nav-max-width: $nav-normal-max-width !default;
// $subsite-nav-margin-top: $spacer !default;
// $subsite-nav-margin-bottom: $spacer !default;
// $subsite-nav-background-colour: $nav-background-colour !default;
// $subsite-nav-contents-max-width: $container-max-width !default;
// $subsite-nav-align-items: $nav-normal-align-items !default; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding !default;
// $subsite-nav-top-level-item-colour: $nav-top-level-item-colour !default;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family !default;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size !default;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour !default;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour !default;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour !default;

// $subsite-nav-submenu-width: $nav-submenu-width !default;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour !default;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow !default;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius !default;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding !default;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour !default;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family !default;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size !default;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight !default;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour !default;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour !default;

// //
// // Cookie consent
//

$footer-admin-my-details-link-enabled: false;