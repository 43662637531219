@if $subsite-enabled == true {
  .menuSub ul {
    @include list-unstyled;
  }

  .menuSub {
    width: 100%;
    @include parentless-container-wrapper($subsite-nav-max-width);
    margin-top: $subsite-nav-margin-top;
    margin-bottom: $subsite-nav-margin-bottom;
    background-color: $subsite-nav-background-colour;

    a {
      display: flex;
      &:hover {
        text-decoration: none;
      }
    }

    ul.topLevel {
      display: flex;
      max-width: $subsite-nav-contents-max-width;
      margin-left: auto;
      margin-right: auto;
      justify-content: flex-start;

      @if $subsite-nav-align-items == center {
        justify-content: center;
      }

      @if $subsite-nav-align-items == right {
        justify-content: flex-end;
      }

      li {
        position: relative;
      }

      > li {
        > a {
          padding: $subsite-nav-top-level-item-padding;
          color: $subsite-nav-top-level-item-colour;
          font-family: $subsite-nav-top-level-item-font-family;
          font-size: $subsite-nav-top-level-item-font-size;
          font-weight: $subsite-nav-top-level-item-font-weight;
          background-color: $subsite-nav-top-level-item-background-colour;
        }

        &:hover > a {
          color: $subsite-nav-top-level-item-hover-colour;
          background-color: $subsite-nav-top-level-item-hover-background-colour;
        }
      }
    }

    .subMenu {
      position: absolute;
      z-index: 1;
      display: none;
      background-color: $subsite-nav-submenu-background-colour;

      a {
        padding: $subsite-nav-submenu-item-padding;
        color: $subsite-nav-submenu-item-colour;
        font-family: $subsite-nav-submenu-item-font-family;
        font-size: $subsite-nav-submenu-item-font-size;
        font-weight: $subsite-nav-submenu-item-font-weight;

        &:hover {
          color: $subsite-nav-submenu-item-hover-colour;
          background-color: $subsite-nav-submenu-item-hover-background-colour;
        }
      }
    }

    @media (min-width: $subsite-nav-breakpoint + 1px) {
      .subMenu {
        width: $subsite-nav-submenu-width;
        box-shadow: $subsite-nav-submenu-box-shadow;

        &.level2 {
          border-radius: 0 0 $subsite-nav-submenu-border-radius $subsite-nav-submenu-border-radius;
        }
      }
    }
  }

  @media (max-width: $subsite-nav-breakpoint + 1px) {
    .menuSub {
      ul.topLevel {
        flex-direction: column;
      }

      li.hasSubmenu > a:after {
        display: none; // No chevrons
      }

      .subMenu {
        display: none; // We have no elegant way of revealing these. Subsites don't have a burger menu
      }
    }
  }
}