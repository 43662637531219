@if ($subsite-enabled == true) and ($subsite-logo != 'title') {
  .headerTextSubsite {
    margin: 0 auto;
    padding-top: 0;
    .subsiteTitle {
      font-size: 1rem;
    }
  }

  .subsiteTitle {
    text-indent: -10000px;
    width: $subsite-logo-width;
    height: $subsite-logo-height;
    background-image: url($assets-path+$subsite-logo);
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;
    margin: $subsite-logo-margin;

    @media only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background-image: url($assets-path+$subsite-logo-retina);
    }

    &:hover {
      cursor: pointer;
    }

    @media (max-width: $nav-breakpoint) {
      width: $subsite-logo-width * .75;
      height: $subsite-logo-height * .75;
    }
  }
}

@if ($subsite-enabled == true) and ($subsite-logo == 'title') {
  .headerTextSubsite {
    padding-top: 1.5rem;
  }

  .subsiteTitle {
    pointer-events: none; // Stop the linking behaviour if it's just the title?
    margin: 0;
  }
}
