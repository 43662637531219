@if $subsite-enabled == true {
  // Vertically re-arrange our elements from the native DOM order
  .subsite .contentContainer,
  .subsite .post {
    display: flex;
    flex-direction: column;

    .headerTextSubsite {
      order: 1;
    }

    .menuSub {
      order: 2;
    }

    .headerWrapper.headerWrapperSubsite {
      order: 3;
    }

    .subsiteBody {
      order: 4;
    }

    .subsiteFeedsWrapper {
      order: 5;
    }

    .headerWrapper {
      order: 6;
    }

    .contentBlockWrapper {
      order: 7;
    }
  }

  .headerTextSubsite,
  .subsiteBody {
    width: 100%;
    max-width: $subsite-content-max-width;
    padding-left: $site-bleed;
    padding-right: $site-bleed;
    margin-left: auto;
    margin-right: auto;

    .subsiteTitle {
      font-size: $subsite-title-font-size;
    }
  }

  // Hide the subsite body before we lift it out with JS - stops flash
  .headerTextSubsite > *:not(.subsiteTitle) {
    display: none;
  }

  // Remove link click event from subsite carousel post
  .carouselSubsite .carouselSlideTitle {
    pointer-events: none;
  }

  // Subsite feeds get a description, which ends up as plain text with no HTML element, i.e. useless. Let's just always hide this from the frontend
  .homeFeed.subsiteFeed {
    font-size: 0;
    .feedsTitle {
      // Re-introduce font size here
      font-size: emToRem($feeds-title-font-size);
    }
    .feedList {
      font-size: 1rem;
    }
  }

  // When we're not on the homepage, hide the subsite banner and body
  .subsite:not(.homepage) {
    .headerWrapperSubsite,
    .subsiteBody {
      display: none;
    }
  }

  .subsiteBody {
    padding-top: $header-text-margin-top;
    margin-bottom: $header-text-margin-bottom;
  }

  @if $subsite-hide-main-header == true {
    .subsite .pageHeader {
      display: none;
    }
  }

  @if $subsite-hide-main-tagline == true {
    .subsite .tagline {
      display: none;
    }

    @if ($header-search-enabled == true) and ($subsite-hide-header-search == false) {
      .subsite .header-search {
        flex-grow: 1;
      }
    } @else {
      .subsite .headerContent .mainCallToAction {
        flex-grow: 1;
      }
    }
  }

  @if $subsite-hide-header-search {
    .subsite .header-search {
      display: none;
    }
  }

  @if $subsite-hide-main-cta == true {
    .subsite .mainCallToAction > *:not(.menuMainAlt) {
      display: none;
    }
  }
}
