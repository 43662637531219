@if $home-feed-twitter-enabled == true {
  .homeFeedTwitter .tweetsWrapper {
    display: flex;
    flex-wrap: wrap;

    li {
      margin: $home-feed-twitter-card-gap-width / 2;
      border-radius: $home-feed-twitter-card-border-radius;
      border: $home-feed-twitter-card-border;
      box-shadow: $home-feed-twitter-card-box-shadow;
      text-align: $home-feed-twitter-card-text-align;
      padding: $home-feed-twitter-card-padding;
      background-color: $home-feed-twitter-card-background-colour;
      flex: 1;
      min-width: $home-feed-twitter-card-min-width;
      color: $home-feed-twitter-card-colour;

      * {
        color: inherit;
      }

      a {
        text-decoration: underline;
      }

      > a {
        display: block;
      }

      .profilePic.twitterProfilePic {
        display: block !important; // Always want this regardless of profilePic settings
      }

      time {
        font-size: $font-size-small;
      }
    }
  }

  .homeFeedTwitter .feedActionWrapper {
    margin-top: $gap-width / 2;
    text-align: center;

    .button {
      margin: 0;
    }
  }
}
